<template>
	<MhRouterView class="RbCartView view" :isContentReady="isContentReady">

		<RbAppHeader
			:textRight="_.get( app, 'acfOptions.headerTextRight' )"
		></RbAppHeader>

		<RbAppMenu></RbAppMenu>

		<article class="view__inner">

			<br/>
			<br/>

			<RbCartTable type="cart"></RbCartTable>

			<!--
			<hr/>
			<RbCartTable type="shipping"></RbCartTable>
			<br/>
			<RbCartTable type="payment"></RbCartTable>
			<br/>
			<RbCartTable type="customer"></RbCartTable>
			<hr/>
			<RbCartTable type="finalCustomerOverview"></RbCartTable>
			<br/><br/>
			<RbCartTable type="finalCartOverview"></RbCartTable>
			<br/>
			<RbCartTable type="finalFooter"></RbCartTable>
			<hr/>
			<RbCartTable type="thank-you"></RbCartTable>
			<hr/>
			-->

		</article>

	</MhRouterView>
</template>

<script>
	import { EventBus } from '@/event-bus.js'
	import RestHandler from "/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/mixins/restHandler/v1/restHandler.mixin.js"
	import MhRouterView from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/components/MhRouterView/v4/MhRouterView.vue'

	import RbAppHeader from "@/components/RbAppHeader.vue"
	import RbAppMenu from "@/components/RbAppMenu.vue"
	import RbCartTable from '@/components/RbCartTable.vue'
	import RbAppFooter from "@/components/RbAppFooter.vue"

	export default {
		name: 'RbCartView',
		components: {
			MhRouterView,
			RbAppHeader,
			RbAppMenu,
			RbCartTable,
			RbAppFooter,
		},
		mixins: [ RestHandler ],
		directives: {},
		props: {},
		data() {
			return {
				isContentReady: false,
			}
		},
		computed: {
			app(){
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				//classes.push('new_class')

				return classes
			},
		},
		methods: {
			XXXfetchAll( finalCallback ){
				let thingsToLoad = 1

				const runCallback = ()=>{
					--thingsToLoad

					//console.log( this.$options.name, '• fetchAll() runCallback()', thingsToLoad)

					if( !thingsToLoad && this._.isFunction( finalCallback ) ) finalCallback()
				}

				this.restHandler__fetch({
					action : 'GET',
					route : '/wp-json/mh/v1/posts',
					params : {
						slug : this.$route.params.slug,
					},
					callback : (response) => {
						this.post = response.data.result[0]

						runCallback()
					},
				})
			},
		},
		mounted(){
			this.isContentReady = true
		},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	.RbCartView {}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
