<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<RbCartTable></RbCartTable>

	2019-06-08	init

-->

<template>
	<div class="RbCartTable hSpace hSpace--app" :class="elmClasses">
		<div class="RbCartTable__inner">
			<!-- step : cart -->
			<template v-if="type === 'cart'">
				<!-- header -->
				<div class="RbCartTable__header font--sans font--sizeSmall rbBorder rbBorder--bottom">
					<div class="RbCartTable__headerRow RbCartTable__headerRow--tableTitle RbCartTable__grid RbCartTable__grid--typeTableTitleRow">
						<div class="RbCartTable__headerColumn"></div>
						<div class="RbCartTable__headerColumn font font--serif font--sizeBig">Cart</div>
					</div>
					<div class="RbCartTable__headerRow RbCartTable__grid RbCartTable__grid--typeProductsHeaderRow"
					     v-if="!$root.isBelowDt">
						<div class="RbCartTable__headerColumn font font--uppercase">Produkt</div>
						<div class="RbCartTable__headerColumn flex flex--right font font--uppercase">Einzelpreis</div>
						<div class="RbCartTable__headerColumn flex flex--center font font--uppercase">Menge</div>
						<div class="RbCartTable__headerColumn flex flex--right font font--uppercase">Summe</div>
						<div class="RbCartTable__headerColumn"></div>
					</div>
				</div>
				<!-- body: products + sums -->
				<div class="RbCartTable__body font--sans font--sizeDefault">
					<!-- products -->
					<div class="RbCartTable__bodyRow RbCartTable__grid RbCartTable__grid--typeProductsBodyRow rbBorder rbBorder--light rbBorder--bottom"
							v-for="(cartItem, index) in cartItems"
							:key="'si' + index">
						<div class="RbCartTable__bodyColumn">
							{{ cartItem.product.author }} <br />
							{{ cartItem.product.title }} <br />
						</div>
						<!-- dt only -->
						<div class="RbCartTable__bodyColumn flex flex--right" v-if="!$root.isBelowDt"
							 v-html="getFormatedPrice(cartItem.product.bruttoPrice)"
						></div>
						<div class="RbCartTable__bodyColumn" v-if="!$root.isBelowDt">
							<div class="RbCartTable__amountNav richText">
								<a class="RbCartTable__amountNavBtn"
								   :class="{ 'RbCartTable__amountNavBtn--isDisabled': cartItem.amount < 2 }"
								   @click="changeCartItemAmount(cartItem.product.id, -1)"
								>–</a>
								<span class="RbCartTable__amountNavNumber">{{ cartItem.amount }}</span>
								<a class="RbCartTable__amountNavBtn"
								   @click="changeCartItemAmount(cartItem.product.id, 1)"
								>+</a>
							</div>
						</div>
						<div class="RbCartTable__bodyColumn flex flex--right" v-if="!$root.isBelowDt"
							 v-html="getFormatedPrice(cartItem.product.bruttoPrice * cartItem.amount)"
						></div>
						<div class="RbCartTable__bodyColumn richText flex flex--right" v-if="!$root.isBelowDt">
							<a class="link link--noUnderline" @click="removeCartItem(cartItem.product.id)">×</a>
						</div>
						<!-- md only -->
						<div class="RbCartTable__bodyColumn richText text text--right font font--sans font--sizeDefault" v-if="$root.isBelowDt">
							<span> {{ cartItem.amount }} × </span>
							<span v-html="getFormatedPrice(cartItem.product.bruttoPrice)"></span><br/>
							<a class="link link--noUnderline" @click="removeCartItem(cartItem.product.id)">Entfernen</a><br/>
						</div>
					</div>

					<!-- no products in cart -->
					<div class="RbCartTable__bodyRow rbBorder rbBorder--light rbBorder--bottom"
							v-if="!_.size( cartItems )" style="grid-colum: span 2;">
						<div class="RbCartTable__bodyColumn">
							Es befinden sich keine Produkte im Warenkorb.<br/>
							<br/>
							<br/>
						</div>
						<div class="RbCartTable__bodyColumn flex flex--right"></div>
						<div class="RbCartTable__bodyColumn"></div>
						<div class="RbCartTable__bodyColumn"></div>
						<div class="RbCartTable__bodyColumn"></div>
					</div>

					<!-- netto, mwst, brutto -->
					<div class="RbCartTable__bodyRow RbCartTable__bodyRow--noPaddingBottom RbCartTable__grid RbCartTable__grid--typeProductsFooterRow rbBorder rbBorder--light rbBorder--top">
						<div class="RbCartTable__bodyColumn" v-if="!$root.isBelowDt"></div>
						<div class="RbCartTable__bodyColumn flex flex--bottom flex--right font font--sans font--sizeSmall font--uppercase">
							Netto<br/>
						</div>
						<div class="RbCartTable__bodyColumn" v-if="!$root.isBelowDt"></div>
						<div class="RbCartTable__bodyColumn flex flex--right"
								v-html="getFormatedPrice(this.productsNettoPrice)"
						></div>
						<div class="RbCartTable__bodyColumn" v-if="!$root.isBelowDt"></div>
					</div>
					<div class="RbCartTable__bodyRow RbCartTable__grid RbCartTable__grid--typeProductsFooterRow rbBorder rbBorder--bottom">
						<div class="RbCartTable__bodyColumn" v-if="!$root.isBelowDt"></div>
						<div class="RbCartTable__bodyColumn flex flex--bottom flex--right font font--sans font--sizeSmall font--uppercase">
							Mwst.
						</div>
						<div class="RbCartTable__bodyColumn" v-if="!$root.isBelowDt"></div>
						<div class="RbCartTable__bodyColumn flex flex--right"
								v-html="getFormatedPrice(this.productsTaxesPrice)"
						></div>
						<div class="RbCartTable__bodyColumn" v-if="!$root.isBelowDt"></div>
					</div>
					<div class="RbCartTable__bodyRow RbCartTable__grid RbCartTable__grid--typeProductsFooterRow rbBorder rbBorder--bottom">
						<div class="RbCartTable__bodyColumn" v-if="!$root.isBelowDt"></div>
						<div class="RbCartTable__bodyColumn flex flex--bottom flex--right font font--sans font--sizeSmall font--uppercase">
							Brutto
						</div>
						<div class="RbCartTable__bodyColumn" v-if="!$root.isBelowDt"></div>
						<div class="RbCartTable__bodyColumn flex flex--right"
								v-html="getFormatedPrice(this.productsBruttoPrice)"
						></div>
						<div class="RbCartTable__bodyColumn" v-if="!$root.isBelowDt"></div>
					</div>
				</div>

				<br />

				<!-- goto next step -->
				<div class="flex flex--right">
					<BaseButton class="RbCartTable__button"
						@click.native="$router.push('/checkout/customer/')"
						:isDisabled="!isStepValid('cart')">
						<template slot="before"></template>
						<template slot="default">Zum Checkout</template>
						<template slot="after">
							<MhIcon type="arrow-right"></MhIcon>
						</template>
					</BaseButton>
				</div>

			</template>

			<!-- step : customer -->
			<template v-if="type === 'shipping'">
				<!-- header -->
				<div class="RbCartTable__header font--sans font--sizeSmall rbBorder rbBorder--bottom">
					<div class="RbCartTable__headerRow RbCartTable__headerRow--tableTitle RbCartTable__grid RbCartTable__grid--typeTableTitleRow">
						<div class="RbCartTable__headerColumn"></div>
						<div class="RbCartTable__headerColumn font font--serif font--sizeBig">Checkout</div>
					</div>
					<div class="RbCartTable__headerRow RbCartTable__grid RbCartTable__grid--typeShippingHeaderRow">
						<div class="RbCartTable__headerColumn font font--uppercase">Versandoptionen</div>
						<div class="RbCartTable__headerColumn flex flex--right">1/3</div>
					</div>
				</div>

				<!-- versandoptionen -->
				<div class="RbCartTable__body font--sans font--sizeDefault">
					<label class="RbCartTable__bodyRow RbCartTable__grid RbCartTable__grid--typeShippingBodyRow rbBorder rbBorder--light rbBorder--bottom"
						 v-for="(shippingOption, index) in shippingOptions"
						 :key="'so' + index">
						<div class="RbCartTable__bodyColumn flex flex--center">
							<BaseRadio
								:name="'shippingOption'"
								:checkedValue="shippingOption.key"
								:value="getCustomerProp('shippingOption')"
								@input="
									(e) => {
										setCustomerProp('shippingOption', e)
									}
								"
							></BaseRadio>
						</div>
						<div class="RbCartTable__bodyColumn">
							<div v-html="shippingOption.label"></div>
							<div class="font--sans font--sizeSmall">
								<div v-html="shippingOption.desc" v-if="$root.isBelowDt"></div>
							</div>
						</div>
						<div class="RbCartTable__bodyColumn font--sans font--sizeSmall" v-if="!$root.isBelowDt"
							 v-html="shippingOption.desc"
						></div>
					</label>
				</div>
			</template>
			<template v-if="type === 'payment'">
				<!-- header -->
				<div class="RbCartTable__header font--sans font--sizeSmall rbBorder rbBorder--bottom">
					<div class="RbCartTable__headerRow RbCartTable__grid RbCartTable__grid--typePaymentHeaderRow">
						<div class="RbCartTable__headerColumn font font--uppercase">Bezahlmethode</div>
						<div class="RbCartTable__headerColumn flex flex--right">2/3</div>
					</div>
				</div>

				<!-- bezahloptionen -->
				<div class="RbCartTable__body font--sans font--sizeDefault">
					<label class="RbCartTable__bodyRow RbCartTable__grid RbCartTable__grid--typePaymentBodyRow rbBorder rbBorder--bottom"
						 v-for="(paymentOption, index) in paymentOptions"
						 :key="'po' + index">
						<div class="RbCartTable__bodyColumn flex flex--center">
							<BaseRadio
								:name="'paymentOption'"
								:checkedValue="'invoice'"
								:value="getCustomerProp('paymentOption')"
								@input="
									(e) => {
										setCustomerProp('paymentOption', e)
									}
								"
							></BaseRadio>
						</div>
						<div class="RbCartTable__bodyColumn">
							<div v-html="paymentOption.label"></div>
							<div class="font--sans font--sizeSmall">
								<div v-html="paymentOption.desc" v-if="$root.isBelowDt"></div>
							</div>
						</div>
						<div class="RbCartTable__bodyColumn font--sans font--sizeSmall"  v-if="!$root.isBelowDt"
							 v-html="paymentOption.desc"
						></div>
					</label>
				</div>
			</template>
			<template v-if="type === 'customer'">
				<!-- header -->
				<div class="RbCartTable__header font--sans font--sizeSmall rbBorder rbBorder--bottom">
					<div class="RbCartTable__headerRow RbCartTable__grid RbCartTable__grid--typeCustomerHeaderRow">
						<div class="RbCartTable__headerColumn font font--uppercase">Rechnungs- und Lieferadresse</div>
						<div class="RbCartTable__headerColumn flex flex--right">3/3</div>
					</div>
				</div>

				<!-- rechnungs- und lieferadresse -->
				<div class="RbCartTable__body font--sans font--sizeDefault">
					<!-- customer address for billing and delivering -->
					<div class="RbCartTable__bodyRow RbCartTable__grid RbCartTable__grid--typeCustomerBodyRow rbBorder rbBorder--light rbBorder--bottom"
						 v-for="(field, index) in customerFields"
						 :key="'cF' + index">
						<div class="RbCartTable__bodyColumn flex flex--bottom handleHyphens font font--sans font--sizeSmall font--uppercase">
							{{ field.label }}
							<span class="RbCartTable__requiredStar" v-if="field.required">*</span>
						</div>
						<div class="RbCartTable__bodyColumn">
							<template v-if="field.type === 'textarea'">
								<BaseTextarea
									:value="getCustomerProp('invoiceAddress.' + field.key)"
									:placeholder="''"
									:required="false"
									:disabled="false"
									:resizeable="'vertical'"
									:debounce="true"
									:debounceDelay="250"
									@change="(e) => {}"
									@input="
										(e) => {
											setCustomerProp('invoiceAddress.' + field.key, e)
										}
									"
								></BaseTextarea>
							</template>
							<template v-else>
								<BaseText
									:value="getCustomerProp('invoiceAddress.' + field.key)"
									:placeholder="''"
									:required="false"
									:disabled="false"
									:hasClearButton="true"
									:debounce="true"
									:debounceDelay="250"
									@input="
										(e) => {
											setCustomerProp('invoiceAddress.' + field.key, e)
										}
									"
								></BaseText>
							</template>
						</div>
					</div>
					<!-- checkbox: different delivery address -->
					<div class="RbCartTable__bodyRow RbCartTable__grid RbCartTable__grid--typeCustomerBodyRow">
						<div class="RbCartTable__bodyColumn"></div>
						<label class="RbCartTable__bodyColumn">
							<div class="flex flex--middle">
								<BaseCheckbox
									:value="getCustomerProp('hasDifferentDeliveryAddress')"
									@input="
										(e) => {
											setCustomerProp('hasDifferentDeliveryAddress', e)
										}
									"
								></BaseCheckbox>
								<p class="hSpace hSpace--medium font font--sans font--sizeSmall noSelect">
									Abweichende Lieferaddresse
								</p>
							</div>
						</label>
					</div>
					<!-- customer address for delivering-->
					<div v-show-slide="getCustomerProp('hasDifferentDeliveryAddress')">
						<div class="RbCartTable__bodyRow RbCartTable__grid RbCartTable__grid--typeCustomerBodyRow rbBorder rbBorder--light rbBorder--bottom"
							 v-for="(field, index) in customerFields"
							 :key="'cF2' + index">
							<div class="RbCartTable__bodyColumn flex flex--bottom handleHyphens font font--sans font--sizeSmall font--uppercase">
								{{ field.label }}
								<span class="RbCartTable__requiredStar" v-if="field.required">*</span>
							</div>
							<div class="RbCartTable__bodyColumn">
								<template v-if="field.type === 'textarea'">
									<BaseTextarea
										:value="getCustomerProp('deliveryAddress.' + field.key)"
										:placeholder="''"
										:required="false"
										:disabled="false"
										:resizeable="'vertical'"
										:debounce="true"
										:debounceDelay="250"
										@change="(e) => {}"
										@input="
											(e) => {
												setCustomerProp('deliveryAddress.' + field.key, e)
											}
										"
									></BaseTextarea>
								</template>
								<template v-else>
									<BaseText
										:value="getCustomerProp('deliveryAddress.' + field.key)"
										:placeholder="''"
										:required="false"
										:disabled="false"
										:hasClearButton="true"
										:debounce="true"
										:debounceDelay="250"
										@input="
											(e) => {
												setCustomerProp('deliveryAddress.' + field.key, e)
											}
										"
									></BaseText>
								</template>
							</div>
						</div>
					</div>
				</div>

				<br />

				<!-- goto next step -->
				<div class="flex flex--right">
					<BaseButton class="RbCartTable__button" :isOutlined="true" @click.native="$router.push('/cart/')">
						<template slot="before">
							<MhIcon type="arrow-left"></MhIcon>
						</template>
						<template slot="default">Zurück</template>
						<template slot="after"></template>
					</BaseButton>
					<BaseButton class="RbCartTable__button"
						@click.native="$router.push('/checkout/finish/')"
						:isDisabled="!isStepValid('customer')">
						<template slot="before"></template>
						<template slot="default">Zur Kasse</template>
						<template slot="after">
							<MhIcon type="arrow-right"></MhIcon>
						</template>
					</BaseButton>
				</div>
			</template>

			<!-- step : finish -->
			<template v-if="type === 'finalCustomerOverview'">
				<!-- header -->
				<div class="RbCartTable__header font--sans font--sizeSmall rbBorder rbBorder--bottom">
					<div class="RbCartTable__headerRow RbCartTable__grid RbCartTable__grid--typeTableTitleRow">
						<div class="RbCartTable__headerColumn"></div>
						<div class="RbCartTable__headerColumn font font--serif font--sizeBig">Bestellübersicht</div>
					</div>
				</div>

				<!-- order data: addresses, payment and shipping options -->
				<div class="RbCartTable__body rbBorder rbBorder--bottom rbBorder--light">
					<!-- Rechnungsadresse -->
					<div class="RbCartTable__bodyRow RbCartTable__grid RbCartTable__grid--typeOrderOverviewBody rbBorder rbBorder--light rbBorder--bottom">
						<div class="RbCartTable__bodyColumn font--sans font--uppercase font--sizeSmall">
							<template v-if="getCustomerProp('hasDifferentDeliveryAddress')">
								Rechnungs&shy;adresse
							</template>
							<template v-else>
								Rechnungs- und Liefer&shy;adresse
							</template>
						</div>
						<div class="RbCartTable__bodyColumn font--sans font--sizeDefault">
							<div class="hideIfEmpty" v-html="getCustomerProp('invoiceAddress.company')"></div>
							<div class="hideIfEmpty" v-html="getCustomerProp('invoiceAddress.name')"></div>
							<div class="hideIfEmpty" v-html="getCustomerProp('invoiceAddress.street')"></div>
							<div class="hideIfEmpty"
								 v-html="
									getCustomerProp('invoiceAddress.zip') +
									', ' +
									getCustomerProp('invoiceAddress.city')
								 "
							></div>
							<div class="hideIfEmpty" v-html="getCustomerProp('invoiceAddress.mail')"></div>
							<div class="richText" v-if="$root.isBelowDt">
								<router-link :to="'/checkout/customer/'">Ändern</router-link>
							</div>
						</div>
						<div class="RbCartTable__bodyColumn flex flex--right richText font--sans font--sizeSmall" v-if="!$root.isBelowDt">
							<router-link :to="'/checkout/customer/'">Ändern</router-link>
						</div>
					</div>
					<!-- Lieferaddresse -->
					<div class="RbCartTable__bodyRow RbCartTable__grid RbCartTable__grid--typeOrderOverviewBody rbBorder rbBorder--light rbBorder--bottom"
					     v-if="getCustomerProp('hasDifferentDeliveryAddress')">
						<div class="RbCartTable__bodyColumn font--sans font--uppercase font--sizeSmall">
							Liefer&shy;adresse
						</div>
						<div class="RbCartTable__bodyColumn font--sans font--sizeDefault">
							<div class="hideIfEmpty" v-html="getCustomerProp('deliveryAddress.company')"></div>
							<div class="hideIfEmpty" v-html="getCustomerProp('deliveryAddress.name')"></div>
							<div class="hideIfEmpty" v-html="getCustomerProp('deliveryAddress.street')"></div>
							<div
								class="hideIfEmpty"
								v-html="
									getCustomerProp('deliveryAddress.zip') +
									', ' +
									getCustomerProp('deliveryAddress.city')
								"
							></div>
							<div class="hideIfEmpty" v-html="getCustomerProp('deliveryAddress.mail')"></div>
							<div class="richText" v-if="$root.isBelowDt">
								<router-link :to="'/checkout/customer/'">Ändern</router-link>
							</div>
						</div>
						<div class="RbCartTable__bodyColumn flex flex--right richText font--sans font--sizeSmall" v-if="!$root.isBelowDt">
							<router-link :to="'/checkout/customer/'">Ändern</router-link>
						</div>
					</div>
					<!-- Versandoption -->
					<div class="RbCartTable__bodyRow RbCartTable__grid RbCartTable__grid--typeOrderOverviewBody rbBorder rbBorder--light rbBorder--bottom">
						<div class="RbCartTable__bodyColumn font--sans font--uppercase font--sizeSmall">
							Versand&shy;option
						</div>
						<div class="RbCartTable__bodyColumn font--sans font--sizeDefault">
							<span v-html="_.get(_.find(shippingOptions, { key: getCustomerProp('shippingOption') }), 'label')"></span>
							<div class="richText" v-if="$root.isBelowDt">
								<router-link :to="'/checkout/customer/'">Ändern</router-link>
							</div>
						</div>
						<div class="RbCartTable__bodyColumn flex flex--right richText font--sans font--sizeSmall" v-if="!$root.isBelowDt">
							<router-link :to="'/checkout/customer/'">Ändern</router-link>
						</div>
					</div>
					<!-- Bezahlmethode -->
					<div class="RbCartTable__bodyRow RbCartTable__grid RbCartTable__grid--typeOrderOverviewBody rbBorder rbBorder--light rbBorder--bottom">
						<div class="RbCartTable__bodyColumn font--sans font--uppercase font--sizeSmall">
							Bezahl&shy;methode
						</div>
						<div class="RbCartTable__bodyColumn font--sans font--sizeDefault">
							<span v-html="_.get(_.find(paymentOptions, { key: getCustomerProp('paymentOption') }), 'label')"></span>
							<div class="richText" v-if="$root.isBelowDt">
								<router-link :to="'/checkout/customer/'">Ändern</router-link>
							</div>
						</div>
						<div class="RbCartTable__bodyColumn flex flex--right richText font--sans font--sizeSmall" v-if="!$root.isBelowDt">
							<router-link :to="'/checkout/customer/'">Ändern</router-link>
						</div>
					</div>
				</div>
			</template>
			<template v-if="type === 'finalCartOverview'">
				<!-- header -->
				<div class="RbCartTable__header font--sans font--sizeSmall rbBorder rbBorder--bottom">
					<div class="RbCartTable__headerRow RbCartTable__grid RbCartTable__grid--typeProductsHeaderRow" v-if="!$root.isBelowDt">
						<div class="RbCartTable__headerColumn font font--uppercase">Produkt</div>
						<div class="RbCartTable__headerColumn flex flex--right font font--uppercase">Einzelpreis</div>
						<div class="RbCartTable__headerColumn flex flex--center font font--uppercase">Menge</div>
						<div class="RbCartTable__headerColumn flex flex--right font font--uppercase">Summe</div>
						<div class="RbCartTable__headerColumn"></div>
					</div>
					<div class="RbCartTable__headerRow RbCartTable__grid RbCartTable__grid--typeProductsHeaderRow" v-if="$root.isBelowDt">
						<div class="RbCartTable__headerColumn font font--uppercase">Produkte</div>
					</div>
				</div>
				<!-- body: products + sums -->
				<div class="RbCartTable__body font--sans font--sizeDefault">
					<div class="RbCartTable__body font--sans font--sizeDefault">
						<!-- products -->
						<div class="RbCartTable__bodyRow RbCartTable__grid RbCartTable__grid--typeProductsBodyRow rbBorder rbBorder--light rbBorder--bottom"
							 v-for="(cartItem, index) in cartItems"
							 :key="'si' + index">
							<div class="RbCartTable__bodyColumn">
								{{ cartItem.product.author }} <br />
								{{ cartItem.product.title }} <br />
							</div>
							<!-- dt only -->
							<div class="RbCartTable__bodyColumn flex flex--right" v-if="!$root.isBelowDt"
								 v-html="getFormatedPrice(cartItem.product.bruttoPrice)"
							></div>
							<div class="RbCartTable__bodyColumn" v-if="!$root.isBelowDt">
								<div class="RbCartTable__amountNav richText">
									<a class="RbCartTable__amountNavBtn"
									   :class="{ 'RbCartTable__amountNavBtn--isDisabled': cartItem.amount < 2 }"
									   @click="changeCartItemAmount(cartItem.product.id, -1)">–</a>
									<span class="RbCartTable__amountNavNumber">{{ cartItem.amount }}</span>
									<a class="RbCartTable__amountNavBtn" @click="changeCartItemAmount(cartItem.product.id, 1)">+</a>
								</div>
							</div>
							<div class="RbCartTable__bodyColumn flex flex--right" v-if="!$root.isBelowDt"
								 v-html="getFormatedPrice(cartItem.product.bruttoPrice * cartItem.amount)">
							</div>
							<div class="RbCartTable__bodyColumn richText flex flex--right" v-if="!$root.isBelowDt">
								<a class="link link--noUnderline noSelect"
								   @click="removeCartItem(cartItem.product.id)">
								×</a>
							</div>
							<!-- md only -->
							<div class="RbCartTable__bodyColumn richText text text--right" v-if="$root.isBelowDt">
								<div class="font font--sans XXXfont--sizeSmall">
									<span> {{ cartItem.amount }} × </span>
									<span v-html="getFormatedPrice(cartItem.product.bruttoPrice)"></span><br/>
								</div>
								<a class="link link--noUnderline" @click="removeCartItem(cartItem.product.id)">Entfernen</a><br/>
							</div>
						</div>

						<!-- no products in cart -->
						<div class="RbCartTable__bodyRow rbBorder rbBorder--light rbBorder--bottom"
						     v-if="!_.size( cartItems )" style="grid-colum: span 2;">
							<div class="RbCartTable__bodyColumn">
								Es befinden sich keine Produkte im Warenkorb.<br/>
								<br/>
								<br/>
							</div>
							<div class="RbCartTable__bodyColumn flex flex--right"></div>
							<div class="RbCartTable__bodyColumn"></div>
							<div class="RbCartTable__bodyColumn"></div>
							<div class="RbCartTable__bodyColumn"></div>
						</div>

						<!-- netto -->
						<div class="RbCartTable__bodyRow RbCartTable__bodyRow--noPaddingBottom RbCartTable__grid RbCartTable__grid--typeProductsFooterRow rbBorder rbBorder--light rbBorder--top">
							<div class="RbCartTable__bodyColumn" v-if="!$root.isBelowDt"></div>
							<div class="RbCartTable__bodyColumn flex flex--bottom flex--right font font--sans font--sizeSmall font--uppercase">
								Netto<br/>
							</div>
							<div class="RbCartTable__bodyColumn" v-if="!$root.isBelowDt"></div>
							<div class="RbCartTable__bodyColumn flex flex--right"
								 v-html="getFormatedPrice(this.productsNettoPrice)"
							></div>
							<div class="RbCartTable__bodyColumn" v-if="!$root.isBelowDt"></div>
						</div>
						<!-- versand -->
						<div class="RbCartTable__bodyRow RbCartTable__bodyRow--noPaddingBottom RbCartTable__grid RbCartTable__grid--typeProductsFooterRow">
							<div class="RbCartTable__bodyColumn" v-if="!$root.isBelowDt"></div>
							<div class="RbCartTable__bodyColumn flex flex--bottom flex--right font font--sans font--sizeSmall font--uppercase">
								Versand
							</div>
							<div class="RbCartTable__bodyColumn" v-if="!$root.isBelowDt"></div>
							<div class="RbCartTable__bodyColumn flex flex--right"
								 v-html="getFormatedPrice(this.shippingNettoPrice)"
							></div>
							<div class="RbCartTable__bodyColumn" v-if="!$root.isBelowDt"></div>
						</div>
						<!-- mwst -->
						<div class="RbCartTable__bodyRow RbCartTable__grid RbCartTable__grid--typeProductsFooterRow rbBorder rbBorder--bottom">
							<div class="RbCartTable__bodyColumn" v-if="!$root.isBelowDt"></div>
							<div class="RbCartTable__bodyColumn flex flex--bottom flex--right font font--sans font--sizeSmall font--uppercase">
								Mwst.
							</div>
							<div class="RbCartTable__bodyColumn" v-if="!$root.isBelowDt"></div>
							<div class="RbCartTable__bodyColumn flex flex--right"
								 v-html="getFormatedPrice(this.totalTaxesPrice)"
							></div>
							<div class="RbCartTable__bodyColumn" v-if="!$root.isBelowDt"></div>
						</div>
						<!-- brutto -->
						<div class="RbCartTable__bodyRow RbCartTable__grid RbCartTable__grid--typeProductsFooterRow rbBorder rbBorder--bottom">
							<div class="RbCartTable__bodyColumn" v-if="!$root.isBelowDt"></div>
							<div class="RbCartTable__bodyColumn flex flex--bottom flex--right font font--sans font--sizeSmall font--uppercase">
								Brutto
							</div>
							<div class="RbCartTable__bodyColumn" v-if="!$root.isBelowDt"></div>
							<div class="RbCartTable__bodyColumn flex flex--right"
								 v-html="getFormatedPrice(this.totalBruttoPrice)"
							></div>
							<div class="RbCartTable__bodyColumn" v-if="!$root.isBelowDt"></div>
						</div>
					</div>
				</div>
			</template>
			<template v-if="type === 'finalFooter'">
				<!-- checkboxes for agbs + privacy + newsletter -->
				<div class="">
					<!-- agbs -->
					<label class="flex flex--top">
						<BaseCheckbox
							:value="getCustomerProp('acceptedLegals')"
							:disabled="false"
							@change="(e) => {}"
							@input="(e) => {
								setCustomerProp('acceptedLegals', e)
							}"
						></BaseCheckbox>
						<p class="noSelect richText hSpace hSpace--default font font--sans font--sizeSmall">
							Hiermit bestätige ich, dass ich die <a href="/agb/">Allgemeinen Geschäftsbedingungen</a> gelesen, verstanden und
							akzeptiert habe.
							<span class="RbCartTable__requiredStar">*</span>
						</p>
					</label>
					<!-- privacy -->
					<label class="flex flex--top vSpace vSpace--default">
						<BaseCheckbox
							:value="getCustomerProp('acceptedPrivacy')"
							:disabled="false"
							@change="(e) => {}"
							@input="(e) => {
								setCustomerProp('acceptedPrivacy', e)
							}"
						></BaseCheckbox>
						<p class="noSelect richText hSpace hSpace--default font font--sans font--sizeSmall">
							Hiermit bestätige ich, dass ich die <a href="/privacy/">Datenschutzerklärung</a> gelesen, verstanden und akzeptiert
							habe.
							<span class="RbCartTable__requiredStar">*</span>
						</p>
					</label>
					<!-- newsletter -->
					<label class="flex flex--top">
						<BaseCheckbox
							:value="getCustomerProp('hasNewsletterSubscription')"
							:disabled="false"
							@change="(e) => {}"
							@input="(e) => {
								setCustomerProp('hasNewsletterSubscription', e)
							}"
						></BaseCheckbox>
						<p class="noSelect richText font--sizeSmall hSpace hSpace--default font font--sans font--sizeSmall">
							Ich möchte den Newsletter abonieren.
						</p>
					</label>
				</div>

				<br />

				<!-- goto next step -->
				<div class="flex flex--right">
					<BaseButton class="RbCartTable__button"
						:isOutlined="true"
						@click.native="$router.push('/checkout/customer/')"
						v-if="!$root.isBelowDt">
						<template slot="before">
							<MhIcon type="arrow-left"></MhIcon>
						</template>
						<template slot="default">Zurück</template>
						<template slot="after"></template>
					</BaseButton>
					<BaseButton class="RbCartTable__button"
						:showSpinner="isSubmittingOrder"
						@click.native="submitOrder"
						:isDisabled="!isStepValid('finish')">
						<template slot="before"></template>
						<template slot="default">Kostenpflichtig bestellen</template>
						<template slot="after">
							<MhIcon type="arrow-right"></MhIcon>
						</template>
					</BaseButton>
				</div>
			</template>

			<template v-if="type === 'thank-you'">
				<div class="font--serif font--sizeBig">
					Vielen Dank für die Bestellung!
				</div>
				<div class="richText font--sans font--sizeDefault">
					<br />
					Weitere Informationen zum Verlauf Ihrer Bestellung erhalten Sie per Email.<br/>
					<br />
					Sollten Sie Fragen oder Änderungswünsche zu Ihrer Bestellung haben, senden Sie
					uns eine Email an <a href="mailto:books@rotorbooks.de">books@rotorbooks.de</a>
					oder rufen Sie uns gerne an unter +49 (0)341 – 248 101 03<br />
					<br />
					Mit herzlichen Grüßen,<br />
					ROTORBOOKS<br />
				</div>
			</template>
		</div>
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	import { EventBus } from '@/event-bus.js'
	import RbShopMixin from '@/components/RbShop/RbShop.mixin.js'
	import RbText from '@/components/RbText.vue'

	import BaseRadio from '/Users/Mario/Dropbox/htdocs/2020-09-17__MhForm/vue-cli-dev/src/components/MhForm/v4/BaseRadio.vue'
	import BaseText from '/Users/Mario/Dropbox/htdocs/2020-09-17__MhForm/vue-cli-dev/src/components/MhForm/v4/BaseText.vue'
	import BaseTextarea from '/Users/Mario/Dropbox/htdocs/2020-09-17__MhForm/vue-cli-dev/src/components/MhForm/v4/BaseTextarea.vue'
	import BaseCheckbox from '/Users/Mario/Dropbox/htdocs/2020-09-17__MhForm/vue-cli-dev/src/components/MhForm/v4/BaseCheckbox.vue'
	import BaseButton from '/Users/Mario/Dropbox/htdocs/2020-09-17__MhForm/vue-cli-dev/src/components/MhForm/v4/BaseButton.vue'
	import MhIcon from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/components/MhIcon/v2/MhIcon.vue'

	export default {
		name: 'RbCartTable',
		components: {
			RbText,
			BaseRadio,
			BaseText,
			BaseTextarea,
			BaseCheckbox,
			BaseButton,
			MhIcon,
		},
		mixins: [RbShopMixin],
		props: {
			//imageObject: [Object, Boolean],
			//text: [String],
			type: {
				type: [String, Boolean],
				default: false,
				required: true,
			},
		},
		data() {
			return {}
		},
		watch: {},
		computed: {
			app() {
				return this.$root.$children[0]
			},
			elmClasses() {
				let classes = []

				classes.push(this.$options.name + '--type' + this._.upperFirst(this.type))

				return classes
			},
		},
		methods: {},
		created() {},
		mounted() {},
		destroyed() {},
	}
</script>

<style lang="less">
	@import (reference) '../less/vars.less';
	@import (reference) '../less/mixins.less';
	@import (reference) '../less/atoms.less';

	// debug
	.RbCartTable {
		[showBorders3] & {
			background-color: fade(yellow, 35);
			&__inner {
				& > * {
					outline: 1px solid fade(red, 35);
				}
			}
			&__header {
				position: relative;
				.label('RbCartTable__header');
			}
			&__headerColumn {
				background-color: fade(red, 15);
			}
			&__body {
				position: relative;
				.label('RbCartTable__body');
			}
			&__bodyColumn {
				background-color: fade(red, 15);
			}
		}
	}
	// layout
	.RbCartTable {
		&__headerColumn {
			display: flex;
			align-items: flex-end;
		}

		&__grid {
			.grid;
			.grid--cols-12;
			.grid--colGap;
		}
		&__grid--typeTableTitleRow {
			grid-template-columns: 14rem 8fr;

			@media @mq[md] {
				grid-template-columns: 1fr;
			}
		}

		&__grid--typeProductsHeaderRow {
			grid-template-columns: 1fr 8rem 6rem 8rem 1rem;
		}
		&__grid--typeProductsBodyRow {
			grid-template-columns: 1fr 8rem 6rem 8rem 1rem;

			@media @mq[md] {
				//background-color: red;
				grid-template-columns: 1fr 5.5em;
			}
		}
		&__grid--typeProductsFooterRow {
			grid-template-columns: 1fr 8rem 6rem 8rem 1rem;

			@media @mq[md] {
				//background-color: red;
				grid-template-columns: 1fr 5.5em;
			}
		}

		&__grid--typeShippingHeaderRow {
			grid-template-columns: 1fr 5em;
		}
		&__grid--typeShippingBodyRow {
			grid-template-columns: 3rem 15em 1fr;

			@media @mq[md] {
				//background-color: red;
				grid-template-columns: 2em 1fr;
			}
		}

		&__grid--typePaymentHeaderRow {
			grid-template-columns: 1fr 5em;
		}
		&__grid--typePaymentBodyRow {
			grid-template-columns: 3rem 15em 1fr;

			@media @mq[md] {
				//background-color: red;
				grid-template-columns: 2em 1fr;
			}
		}

		&__grid--typeCustomerHeaderRow {
			grid-template-columns: 1fr 5em;
		}
		&__grid--typeCustomerBodyRow {
			grid-template-columns: 14rem 1fr;

			@media @mq[md] {
				//background-color: red;
				grid-template-columns: 5em 1fr;
			}
		}

		&__grid--typeOrderOverviewBody {
			grid-template-columns: 14rem 1fr 5rem;

			@media @mq[md] {
				//background-color: red;
				grid-template-columns: 5em 1fr;
			}
		}
	}
	// styling
	.RbCartTable {
		&__headerRow,
		&__bodyRow {
			padding: 0.65rem 0;

			&--noPaddingBottom {
				padding-bottom: 0;
			}
		}
		&__headerRow--tableTitle {
			@media @mq[dt] {
				margin-bottom: -3.35em;
			}
			@media @mq[md] {
				margin-bottom: -0.75em;
			}
		}

		&--typeCart &__header {
			&Text {}
			&Text--produkt {}
			&Text--tableTitle {
				transform: translateY(12%);
			}
			&Text--einzelpreis {}
			&Text--menge {}
			&Text--summe {}
		}

		&__requiredStar {
			display: inline-block;
			padding-left: 0.25em;
			color: @swatches[textRed];
		}
	}


	.RbCartTable__amountNav {
		//background-color: fade( red, 20);
		//& > * { outline: 1px solid fade( red, 50 );	}

		display: flex;
		justify-content: space-around;
		//width: 3em;

		&Btn {
			display: flex;
			justify-content: center;
			align-items: center;
			line-height: 1em;
			flex-grow: 1;
			flex-shrink: 0;
			user-select: none;
			&:hover {
				text-decoration: none !important;
			}

			&--isDisabled {
				pointer-events: none;
				opacity: 0.5;
			}
		}
		&Number {
			display: flex;
			justify-content: center;
			flex-grow: 1;
			flex-shrink: 0;
		}
	}

	.RbCartTable__button {
		& + & {
			margin-left: 0.5em;
		}
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
